<template>
<div>
	<b-card class="style-card rounded" v-if="item">
		<template v-slot:header>
			<ul class="toolbar d-flex list-unstyled">
				<li v-if="editable">
					<b-button class="header-btn" size="sm" variant="outline-dark" pill href="#sidebar-1" v-b-toggle @click.prevent="setEditorArgs" v-b-tooltip.hover.bottom="'Edit'" title="Edit">
						<div class="material-icons-outlined md-light">edit</div>
					</b-button>
				</li>
				<li>
					<b-button class="header-btn" size="sm" :variant="item.isFavorite ? 'primary' : 'outline-dark'" @click.prevent="toggleFavorite(item)" pill v-b-tooltip.hover.bottom="'Add to Favorites'" title="Add to Favorites">
						<div class="material-icons-outlined md-light">favorite_border</div>
					</b-button>
				</li>
				<li>
					<b-button v-if="isPro" class="header-btn" size="sm" variant="outline-dark" pill v-b-tooltip.hover.bottom="'Add to Collection'" title="Add to Collection" v-b-modal="'collection-manager-' + item._id">
						<div class="material-icons-outlined md-light">layers</div>
					</b-button>
					<b-button v-else class="header-btn" size="sm" variant="outline-dark" pill v-b-tooltip.hover.bottom="'Add to Collection'" title="Add to Collection" to="/collections">
						<div class="material-icons-outlined md-light">layers</div>
					</b-button>
				</li>
				<li>
					<b-button class="header-btn" size="sm" variant="outline-dark" pill :data-clipboard-text="item.val" @click="makeToast('Copied to clipboard');" v-b-tooltip.hover.bottom="'Copy to Clipboard'" title="Copy">
						<div class="material-icons-outlined md-light">content_copy</div>
					</b-button>
				</li>
			</ul>
			<prism class="prism" ref="prism" language="css" :code="draft ? item.draft : item.val" :plugins="pluginConfigs"></prism>
		</template>
		<b-card-text>
			<b-row>
				<b-col class="special-ellipsis pr-1 align-self-center">
					<a :href="item.key" :title="item.key" target="_blank" class="d-block ellipsis printed-url" v-if="item.key.match('http')">{{ getUrlParts(item.key).hostname }}<span
							class="text-muted">{{ getUrlParts(item.key).pathname }}{{ getUrlParts(item.key).search }}{{ getUrlParts(item.key).hash }}</span></a>
					<a :href="'http://' + item.key" :title="item.key" target="_blank" class="d-block ellipsis printed-url" v-else>{{ item.key }}</a>
				</b-col>
				<b-col v-if="editable" cols="auto" class="align-self-center pr-0 flex-child long-and-truncated ellipsis" :id="'disable-' + item._id">
					<b-form-checkbox class="d-flex align-self-center" name="check-button" switch v-model="item.active" @change="toggleActiveStyle(item)" :disabled="disabled"></b-form-checkbox>
					<b-tooltip :target="'disable-' + item._id" triggers="hover">
						{{ disableSwitchTitle }}
					</b-tooltip>
				</b-col>
				<b-col cols="auto pl-0">
					<StyleMenu :item="item" :draft="draft" :editable="editable"></StyleMenu>
				</b-col>
			</b-row>
		</b-card-text>
	</b-card>
	<CollectionManagerModal :item="item" />
</div>
</template>

<script>
const CollectionManagerModal = () => import('@/components/CollectionManagerModal');
const StyleMenu = () => import('@/components/StyleMenu');
import {
	VuePrismComponent as prism
} from '@aminoeditor/vue-prism';
export default {
	name: 'StyleCard',
	components: {
		CollectionManagerModal,
		StyleMenu,
		prism
	},
	props: [
		'item',
		'draft',
		'offer',
		'disabled'
	],
	data() {
		return {
			animated: false,
			pluginConfigs: {
				whitespace: {
					'remove-trailing': true,
					'remove-indent': true,
					'left-trim': true,
					'right-trim': true
				}
			},
			showModal: false,
			editable: false
		}
	},
	computed: {
		styleSize() {
			return this.item.val.length;
		},
		styleLines() {
			return this.item.val.split(/\r\n|\r|\n/).length;
		},
		disableSwitchTitle() {
			if (this.disabled) {
				return 'All stylesheets are disabled';
			}
			if (!this.editable) {
				return 'Install extension to interact';
			}
			return 'Toggle stylesheet ' + (this.item.active ? 'off' : 'on');
		}
	},
	methods: {
		setEditorArgs() {
			this.$router.replace({
				query: this.$route.query,
				params: {
					style_id: this.item._id
				}
			});
		},
		itemId(pre) {
			return pre + '-' + this.item._id;
		},
		confirmDeleteStyle() {
			this.deleteStyle(this.item);
			this.showModal = false;
		},
		toggleActiveStyle(style) {
			style.patch();
			if (style.active) {
				this.makeToast('Stylesheet enabled');
			} else {
				this.makeToast('Stylesheet disabled');
			}
		},
		toggleFavorite(style) {
			style.isFavorite = !style.isFavorite;
			style.patch();
			if (style.isFavorite) {
				this.makeToast('Added to favorites');
			} else {
				this.makeToast('Removed from favorites');
			}
		},
		openStyleUrl() {
			window.open((this.item.key.match('http') ? this.item.key : 'http://' + this.item.key));
		}
	},
	async mounted () {
		this.editable = await this.extensionExists();
	}
}
</script>

<style lang="scss" scoped>
.special-ellipsis {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.ellipsis {
	max-width: 300px;

	@media (min-width: 2000px) {
		max-width: 200px;
	}

	@media (min-width: 2200px) {
		max-width: 300px;
	}
}

.style-card-list {
	display: block;
}

.style-card {
	&:hover {
		box-shadow: var(--property-box-shadow);
	}

	&.card {
		border-radius: var(--property-border-radius) !important;
		background-color: var(--color-700);
	}

	.toolbar {
		position: absolute;
		top: 1.5rem;
		right: 1.5rem;
		z-index: 10;
		opacity: 0;
		visibility: hidden;

		.material-icons-outlined {
			font-size: 1.125rem !important;
		}

		li {
			&:not(:last-of-type) {
				margin-right: 0.65rem;
			}
		}

		.btn {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 2.25rem !important;
			height: 2.25rem !important;
		}

		.btn.btn-outline-dark {
			background-color: var(--color-700) !important;
			border: 1px solid var(--color-border-helper) !important;
			border: 0;

			&:hover {
				border-color: transparent;
				background-color: var(--color-500) !important;
			}
		}

		.feather {
			margin-top: -1px;
		}
	}

	.card-header {
		min-height: 200px;
		max-height: 200px;
		border-bottom-color: var(--color-500);
		user-select: none;
		position: relative;
		padding: 1.75rem;
		overflow: hidden;

		@media (max-width: 900px) {
			min-height: 120px;
			max-height: 120px;
		}

		.prism {
			display: block;
			max-height: calc(200px - 1.75rem);
			overflow: hidden;
		}

		&:hover {
			&:after {
				transform: translateY(0);
				background-image: linear-gradient(rgba(22,27,34,0.5) 0, rgba(22,27,34,0.85) 100%);
			}

			.toolbar {
				opacity: 1;
				visibility: visible;
			}
		}

		&:after {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			content: "";
			display: block;
			transform: translateY(3rem);
			background-image: linear-gradient(transparent 0, rgba(22,27,34,0.85) 100%);
			z-index: 9;
		}
	}

	.card-body {
		padding: 1.5rem;
	}

	.printed-url {
		width: 100%;
		font-size: 1.15rem;
		font-weight: var(--font-weight-bold);
		color: inherit;

		@media (max-width: 1700px) {
			font-size: 1rem;
		}
	}
}

.action-menu {
	position: relative;

	.dropdown-toggle {
		padding: 0 !important;
		background-color: transparent;
		border-color: transparent;
		color: var(--color-body);

		&.active,
		&:active,
		&:focus,
		&:hover {
			background-color: transparent !important;
			border-color: transparent !important;
		}
	}
}
</style>
